import { Drawer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from '../styles/BuySellDrawer.module.scss';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { colourStylesCurrency } from './SelectCurrencyStyles';
import { colourStylesLeverage } from './SelectLeverageStyles';
import altIcon from '../imgs/altIcon.svg'
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';

export default function EditOrderDrawer({currentRecords,currentRow, open, toggleDrawer, theme, active, eurToUsd, usdToEur,setSnackType,setSnackMessage,handleClick,account }) {
    const [accountType, setAccountType] = useState('Real')
    const { t } = useTranslation()
    const [amount, setAmount] = useState(1)
    const [leverage, setLeverage] = useState(1)
    const [orderType, setOrderType] = useState('')
    const [openWhen, setOpenWhen] = useState('')
    const [TakeProfit, setTakeProfit] = useState('0.000')
    const [StopLoss, setStopLoss] = useState('0.000')

    const handleImgError = (e) => {
        e.target.src = altIcon; // Подставляем альтернативную иконку при ошибке
    };


    const handleChangeTake = (event) => {
        let newValue = event.target.value;
        const re = /^-?\d*[.,]?\d{0,4}$/;

        if (newValue === '' || re.test(newValue)) {
            setTakeProfit(newValue);
        }
    };
    const handleChangeStop = (event) => {
        let newValue = event.target.value;
        const re = /^-?\d*[.,]?\d{0,4}$/;

        if (newValue === '' || re.test(newValue)) {
            setStopLoss(newValue);
        }
    };


    useEffect(() => {
        switch (active?.Type) {
            case 'crypto': setLeverage(10); break;
            case 'shares': setLeverage(100); break;
            case 'share': setLeverage(10); break;
            case 'ruShare': setLeverage(10); break;
            case 'shareIndex': setLeverage(10); break;
            case 'currency': setLeverage(200); break;
            case 'metal': setLeverage(100); break;
            case 'oil_gas': setLeverage(100); break;
            default: setLeverage(1); break;
        }
    }, [active])
    const leverages = [{
        value: 1,
        label: 'x1',
    }, {
        value: 5,
        label: 'x5',
    }, {
        value: 10,
        label: 'x10',
    }, {
        value: 20,
        label: 'x20',
    }, {
        value: 50,
        label: 'x50',
    }, {
        value: 100,
        label: 'x100',
    }, {
        value: 200,
        label: 'x200',
    }, {
        value: 500,
        label: 'x500',
    }]
    async function Submit (){
        if(currentRow?.Status === 'SELL' && +TakeProfit*(account?.AccountCurrency == "EUR"?usdToEur:1) >= (currentRecords.find(el=> el.AssetId == currentRow?.AssetId)?.MarketPrice) ){ 
            return
        }
        if(currentRow?.Status === 'SELL' && +StopLoss*(account?.AccountCurrency == "EUR"?usdToEur:1) <= (currentRecords.find(el=> el.AssetId == currentRow?.AssetId)?.MarketPrice)  && +StopLoss !== 0){ 
            return
        }
        if(currentRow?.Status === 'BUY' && +TakeProfit*(account?.AccountCurrency == "EUR"?usdToEur:1) <= (currentRecords.find(el=> el.AssetId == currentRow?.AssetId)?.MarketPrice) && +TakeProfit*(account?.AccountCurrency == "EUR"?usdToEur:1) !== 0){
                return
            }
        if(currentRow?.Status === 'BUY' && +StopLoss*(account?.AccountCurrency == "EUR"?usdToEur:1) >= (currentRecords.find(el=> el.AssetId == currentRow?.AssetId)?.MarketPrice) ){
            return
        }
        try{
            const {data} = await axios.patch(process.env.REACT_APP_test_url + `/api/assets/user/${currentRow?.Id}`,{
                TakeProfit: TakeProfit?account?.AccountCurrency == "EUR"?TakeProfit*usdToEur:TakeProfit:0,
                StopLoss:StopLoss?account?.AccountCurrency == "EUR"?StopLoss*usdToEur:StopLoss : 0
            },
            {
                headers: {
                    'Authorization': `Bearer ${secureLocalStorage.getItem('token')}`
                }
            })
            if(data) toggleDrawer(false)()
        }catch(e){
            console.log(e)
        }


    }
    const CustomOption = (props) => {
        const { innerProps, innerRef } = props;
        return (
            <div ref={innerRef} {...innerProps} className={props.isSelected ? styles.custom_option_selected : styles.custom_option}>
                <div className={styles.option} style={{ color: props.data.color, backgroundColor: props.data.background, width: 'auto' }}>
                    {props.data.label}
                </div>
            </div>)
    }


    const DropdownIndicator = ({ children, ...props }) => (
        <components.DropdownIndicator {...props}>
            <div className={styles.single_value_container}>
                <div className={styles.custom_icon}>
                    <svg width={"1.7vh"} height={"1.6vh"} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5003 6L8.83366 10L4.16699 6" stroke="#A0A0AB" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                {children}
            </div>
        </components.DropdownIndicator>
    );


    return (
        <Drawer open={open} anchor={window.innerWidth < 600?"bottom":'right'} onClose={toggleDrawer(false)}
            PaperProps={{
                className: styles.paper,
            }}>
            <div className={styles.account_drawer}>
                <div className={styles.close_icon} onClick={toggleDrawer(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none">
                        <path d="M12 4.00003L4 12M3.99997 4L11.9999 12" stroke="#51525C" strokeLinecap="round" />
                    </svg>
                </div>
                <div className={styles.top_drawer}>
                    <div className={styles.drawer_header} style={{justifyContent:'space-between'}}>
                        <span style={{display:'flex',alignItems:'center',justifyContent:'start' , gap:'1.6vh'}}>
                            <img src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${currentRow?.Subtitle?.slice(1)?.slice(0, -1).toLowerCase()}.svg`} alt='icon'
                                onError={handleImgError} />
                            {currentRow?.Subtitle?.slice(1)?.slice(0, -1)}
                            <span style={{fontSize:'12px' , fontWeight:'500',padding:'4px 12px',borderRadius:"1000px" , color: currentRow?.Status?.includes('BUY')?"#00B4A0":currentRow?.Status?.includes('SELL')?"#F63D68":"#E9770E", background: currentRow?.Status?.includes('BUY')?"rgba(0, 180, 160, 0.08)":currentRow?.Status?.includes('SELL')?"rgba(246, 61, 104, 0.08":"rgba(233, 119, 14, 0.08)"}}>{currentRow?.Status?currentRow?.Status:''}</span>
                        </span>
                        <div className={styles.close_icon_mobile} onClick={toggleDrawer(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none">
                                <path d="M12 4.00003L4 12M3.99997 4L11.9999 12" stroke="#51525C" strokeLinecap="round" />
                            </svg>
                        </div>
                    </div>
                    <div className={styles.drawer_body}>
                        <div className={styles.label_select}>
                            <label>{t('Leverage')}</label>
                            <div className={styles.select}>
                                <Select
                                    classNamePrefix="custom_select"
                                    className={`${styles.select_select} custom-select-container`}
                                    placeholder={t('Currency')}
                                    isDisabled
                                    menuPlacement='auto'
                                    options={leverages}
                                    styles={colourStylesLeverage(theme)}
                                    value={leverages.find(el => el.value === leverage)}
                                    components={{ DropdownIndicator, IndicatorSeparator: () => null, Option: CustomOption }}
                                />
                            </div>
                        </div>
                        <div className={styles.label_select}>
                            <label>{t('Min step')}</label>
                            <div className={styles.select}>
                                <Select
                                    classNamePrefix="custom_select"
                                    className={`${styles.select_select} custom-select-container`}
                                    placeholder={t('Min step')}
                                    isDisabled
                                    menuPlacement='auto'
                                    options={[]}
                                    styles={colourStylesCurrency(theme)}
                                    value={{ value: 1, label: '0.1' }}
                                    components={{ DropdownIndicator, IndicatorSeparator: () => null, Option: CustomOption }}
                                />
                            </div>
                        </div>
                        <div className={styles.label_input_new}>
                            <label>{t('Take-Profit')}</label>
                            <input type='number' name='take' placeholder={t('Take-Profit')} value={TakeProfit} onChange={(e) => handleChangeTake(e)}/>
                            {currentRow?.Status === 'BUY'
                                ? +TakeProfit <= (currentRecords.find(el=> el.AssetId == currentRow?.AssetId)?.MarketPrice*(account?.AccountCurrency == "EUR"?eurToUsd:1))?.toFixed(4) && +TakeProfit
                                    ? <p style={{ margin: '0', color: '#F6465D', fontSize: '12px' }}>{t('MustBeHigher')}</p>
                                    : '' : currentRow?.Status === 'SELL' ? +TakeProfit >= (currentRecords.find(el=> el.AssetId == currentRow?.AssetId)?.MarketPrice)?.toFixed(4) ?
                                        <p style={{ margin: '0', color: '#F6465D', fontSize: '12px' }}>{t('MustBeLower')}</p> : "" : ''
                            }
                        </div>
                        <div className={styles.label_input_new}>
                            <label>{t('Stop-Loss')}</label>
                            <input type='number' placeholder={t('Stop-Loss')}  value={StopLoss} onChange={(e) => handleChangeStop(e)}/>
                            {currentRow?.Status === 'BUY'
                                ? +StopLoss >= 
                                (currentRecords.find(el=> el.AssetId == currentRow?.AssetId)?.MarketPrice*(account?.AccountCurrency == "EUR"?eurToUsd:1))?.toFixed(4)
                                    ? <p style={{ margin: '0', color: '#F6465D', fontSize: '12px' }}>{t("MustBeLower")}</p>
                                    : '' 
                                : 
                                currentRow?.Status === 'SELL' 
                                ? 
                                +StopLoss <= (currentRecords.find(el=> el.AssetId == currentRow?.AssetId)?.MarketPrice*(account?.AccountCurrency == "EUR"?eurToUsd:1))?.toFixed(4) 
                                && +StopLoss !==0 ?
                                        <p style={{ margin: '0', color: '#F6465D', fontSize: '12px' }}>{t("MustBeHigher")}</p> : "" : ''
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.drawer_footer}>
                    <button style={{gap:'1.2vh'}} onClick={Submit}>{t('Save Order')}
                        <svg xmlns="http://www.w3.org/2000/svg" width={"1.6vh"} height={"1.6vh"} viewBox="0 0 16 16" fill="none">
                            <g clip-path="url(#clip0_627_6958)">
                                <path d="M1.83301 1.3335C1.83301 1.05735 1.60915 0.833496 1.33301 0.833496C1.05687 0.833496 0.833008 1.05735 0.833008 1.3335V8.03841C0.832999 9.57734 0.832993 10.7834 0.959506 11.7244C1.089 12.6875 1.35923 13.4475 1.95577 14.0441C2.55231 14.6406 3.31232 14.9108 4.27548 15.0403C5.21647 15.1668 6.4225 15.1668 7.96143 15.1668H14.6663C14.9425 15.1668 15.1663 14.943 15.1663 14.6668C15.1663 14.3907 14.9425 14.1668 14.6663 14.1668H7.99967C6.41419 14.1668 5.27538 14.1658 4.40873 14.0492C3.55646 13.9347 3.04264 13.7167 2.66287 13.337C2.2831 12.9572 2.06517 12.4434 1.95059 11.5911C1.83407 10.7245 1.83301 9.58565 1.83301 8.00016V1.3335Z" fill="#FCFCFC" />
                                <path d="M13.058 4.97777C13.2297 4.76152 13.1936 4.447 12.9774 4.27527C12.7611 4.10355 12.4466 4.13964 12.2749 4.35589L10.1962 6.97349C10.0327 7.17938 9.92546 7.31401 9.8371 7.41099C9.75068 7.50586 9.71012 7.53276 9.69166 7.54263C9.55115 7.61775 9.38325 7.6214 9.2396 7.55247C9.22073 7.54341 9.17904 7.5183 9.08857 7.42728C8.99608 7.33423 8.88307 7.20439 8.71077 7.00581L8.70002 6.99341C8.54143 6.81062 8.40619 6.65475 8.28689 6.53473C8.16287 6.40996 8.02726 6.29356 7.85928 6.21295C7.42835 6.00615 6.92466 6.0171 6.50313 6.24244C6.33882 6.33028 6.20839 6.45247 6.08992 6.5825C5.97595 6.7076 5.84762 6.8692 5.69714 7.0587L3.60812 9.68922C3.43639 9.90546 3.47248 10.22 3.68873 10.3917C3.90497 10.5634 4.21949 10.5274 4.39122 10.3111L6.47002 7.69347C6.63352 7.48758 6.74077 7.35296 6.82913 7.25597C6.91556 7.16111 6.95611 7.13421 6.97457 7.12434C7.11508 7.04923 7.28298 7.04557 7.42662 7.11451C7.4455 7.12357 7.48719 7.14868 7.57765 7.2397C7.67015 7.33275 7.78315 7.46258 7.95545 7.66116L7.96622 7.67358C8.12479 7.85634 8.26005 8.01224 8.37934 8.13225C8.50335 8.25702 8.63897 8.37342 8.80695 8.45403C9.23788 8.66083 9.74158 8.64987 10.1631 8.42452C10.3274 8.33668 10.4579 8.21449 10.5763 8.08445C10.6903 7.95937 10.8186 7.79778 10.9691 7.60829L13.058 4.97777Z" fill="#FCFCFC" />
                            </g>
                            <defs>
                                <clipPath id="clip0_627_6958">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </button>
                </div>
            </div>
        </Drawer>
    )
}
