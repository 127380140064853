import React, { createContext, useState, useEffect } from 'react';
import  secureLocalStorage  from  "react-secure-storage";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(secureLocalStorage.getItem("data-theme")?secureLocalStorage.getItem("data-theme"):'dark');
    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
        secureLocalStorage.setItem("data-theme",theme);
    }, [theme]);

    const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
    };
    return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
        {children}
    </ThemeContext.Provider>
    );
};
