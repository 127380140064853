import { Route, Routes } from 'react-router-dom';
import './App.css';
import { ThemeProvider, ThemeContext } from './middleware/ThemeContext.jsx';
import Layout from './components/Layout.jsx';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Login from './pages/Login/Login.jsx';
import Register from './pages/Register/Register.jsx';
import Main from './pages/Main/Main.jsx';
import secureLocalStorage from 'react-secure-storage';
import i18n from './i18n/config';
import AutoLogin from './pages/AutoLogin/AutoLogin.jsx';
import { Alert, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Welcome from './pages/Welcome/Welcome';

function App() {
  const {t} = useTranslation();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [favorites, setFavorites] = useState(() => {
    return JSON.parse(secureLocalStorage.getItem('favorites')) || [];
  });
  const [activeAsset, setActiveAsset] = useState(null);
  const [currentRecords ,setCurrentRecords] = useState([])
  const [profit , setProfit] = useState(0)

  const [activePrice, setActivePrice] = useState(null)
  const [eurToUsd, setEurToUsd] = useState(0);
  const [usdToEur, setUsdToEur] = useState(0);
  const [account,setAccount] = useState(null);
  const [balance , setBalance] = useState(0)
  const [margin , setMargin] = useState(0)
  const [totalBalanceUsd, setTotalBalanceUsd] = useState(0);
  
  useEffect(()=>{
      const fetchEur = async ()=>{
          try{
              const {data} = await axios.get(process.env.REACT_APP_test_url + '/api/currencies/eur')
              if(data){
                  setEurToUsd(data)
              }
          }
          catch(e){
              console.log(e)
          }
      }
      const fetchUsd = async ()=>{
          try{
              const {data} = await axios.get(process.env.REACT_APP_test_url + '/api/currencies/usd')
              if(data){
                  setUsdToEur(data)
              }
          }
          catch(e){
              console.log(e)
          }
      }
      fetchUsd()
      fetchEur()
      if(secureLocalStorage.getItem('lang')){
        i18n.changeLanguage(secureLocalStorage.getItem('lang'));
      }
      else{
        i18n.changeLanguage("ru");
        secureLocalStorage.setItem("lang","ru");
      }
  },[])

  console.log('connected chart')

  useEffect(()=>{
    setInterval(async ()=>{
      if(secureLocalStorage.getItem('user'))
      axios.get(process.env.REACT_APP_test_url + `/api/users/${secureLocalStorage.getItem('user')?.Id}`,{
        headers:{
            'Authorization':'Bearer '+secureLocalStorage.getItem('token')
        }
    })
    .then((res)=>{
        secureLocalStorage.setItem('user',res.data.user);
        secureLocalStorage.setItem('accounts',res.data.accounts);
        secureLocalStorage.setItem('token',res.data.token);
        let account;
        setTotalBalanceUsd(res.data.balance);
        if(secureLocalStorage.getItem("selected_account")){
          account = res.data.accounts.find((val)=>val?.Id == secureLocalStorage.getItem("selected_account")?.Id);
          setBalance(account?.AccountBalance)
          setAccount(account)
          setMargin(account?.Margin);
        }
        else{
          account = res.data.accounts[0];
          setMargin(account?.Margin);
          setBalance(account?.AccountBalance)
          setAccount(account)
        }
        // setBalance(secureLocalStorage.getItem("selected_account")?.AccountBalance)
    })
    .catch((err)=>{
        console.log(err);
    })
    },2000)

    if(secureLocalStorage.getItem('lang')){
      i18n.changeLanguage(secureLocalStorage.getItem('lang'));
    }
    else{
      i18n.changeLanguage("en");
      secureLocalStorage.setItem("lang","en");
    }
  },[])
  
  const toggleFavorite = (id) => {
    const updatedFavorites = favorites.includes(id)
      ? favorites.filter(favId => favId !== id)
      : [...favorites, id];
    setFavorites(updatedFavorites);
    secureLocalStorage.setItem('favorites', JSON.stringify(updatedFavorites));
  }

  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackType, setSnackType] = useState('success');

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  
  const SuccessIcon = () => (
    <div className='success_icon'>
      <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
        <g clipPath="url(#clip0_638_53454)">
          <circle cx="8.00065" cy="8.00016" r="6.66667" stroke="#00B4A0" />
          <path d="M5.66602 8.3335L6.99935 9.66683L10.3327 6.3335" stroke="#00B4A0" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_638_53454">
            <rect width={16} height={16} fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
  
  const ErrorIcon = () => (
    <div className='error_icon'>
      <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
        <path d="M3.54179 7.17433C5.48737 3.72478 6.46015 2 8.00065 2C9.54115 2 10.5139 3.72477 12.4595 7.17432L12.7019 7.60418C14.3187 10.4707 15.1271 11.904 14.3965 12.952C13.6659 14 11.8583 14 8.24309 14H7.75821C4.14302 14 2.33543 14 1.60482 12.952C0.874212 11.904 1.68259 10.4707 3.29935 7.60418L3.54179 7.17433Z" stroke="#F63D68" />
        <path d="M8 5.33333V8.66666" stroke="#F63D68" strokeLinecap="round" />
        <ellipse cx="8.00065" cy="10.6667" rx="0.666667" ry="0.666667" fill="#F63D68" />
      </svg>
  
    </div>
  )
  
  const CloseIcon = ({onClose}) => (
    <div className='close_icon'  onClick={onClose}>
      <svg xmlns="http://www.w3.org/2000/svg" width={'1.2vh'} height={'1.2vh'} viewBox="0 0 12 12" fill="none">
        <path d="M8.24998 3.75002L3.75 8.25M3.74998 3.75L8.24996 8.24998" stroke="#A0A0AB" strokeLinecap="round" />
      </svg>
    </div>
  )
  

  return (
    <div>
      <Routes>
        <Route path={'/autologin/:token/:email/:id'} element={<AutoLogin 
          setAccount = {setAccount}/>}></Route>
          <Route path='/terminal' element={<Layout
            totalBalanceUsd={totalBalanceUsd}
            setActivePrice={setActivePrice}
            activePrice={activePrice}
            setActiveAsset={setActiveAsset}
            activeAsset={activeAsset}
            favorites={favorites}
            toggleFavorite={toggleFavorite}
            setFavorites={setFavorites}
            theme={theme}
            toggleTheme={toggleTheme}
            setCurrentRecords={setCurrentRecords}
            currentRecords={currentRecords}
            account = {account}
            setAccount = {setAccount}
            balance = {balance}
            margin = {margin}
            eurToUsd = {eurToUsd}
            usdToEur = {usdToEur}
            profit={profit}
            setProfit={setProfit}
            setSnackMessage={setSnackMessage}
            setSnackType={setSnackType}
            handleClick={handleClick}
          />}>
          <Route path='/terminal' element={<Main
            setActivePrice={setActivePrice}
            activePrice={activePrice}
            setActiveAsset={setActiveAsset}
            activeAsset={activeAsset}
            favorites={favorites}
            toggleFavorite={toggleFavorite}
            setFavorites={setFavorites}
            theme={theme}
            eurToUsd={eurToUsd}
            usdToEur={usdToEur}
            setSnackMessage={setSnackMessage}
            setSnackType={setSnackType}
            handleClick={handleClick}
            AssetsList={currentRecords}
            account = {account}
            setAccount = {setAccount}
            profit={profit}
            setProfit={setProfit}
          />} />
        </Route>
        <Route path='/login' element={<Login 
          setAccount = {setAccount}/>} />
          <Route path='/' element={<Welcome
            setAccount = {setAccount}/>} />
        <Route path='/register' element={<Register 
          setAccount = {setAccount}/>} />
      </Routes>
      <Snackbar open={open} className='snack_bar' autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <Alert icon={snackType === 'success'?<SuccessIcon/>:<ErrorIcon/>} className='snack_alert' action={<CloseIcon  onClose={handleClose}/>}  onClose={handleClose} severity={snackType} sx={{ width: '100%'}}>
          <h3 className='snack_title'>{snackType === 'success'?t('Done!'):t('Error!')}</h3>
          <p className='snack_text'> {snackMessage}</p>
        </Alert>
      </Snackbar>
    </div>
  );
}

const Root = () => (
  <ThemeProvider>
    <App />
  </ThemeProvider>
);
export default Root;
