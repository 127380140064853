import { Drawer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from '../styles/BuySellDrawer.module.scss';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { colourStylesCurrency } from './SelectCurrencyStyles';
import { colourStylesLeverage } from './SelectLeverageStyles';
import altIcon from '../imgs/altIcon.svg'
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';

export default function BuySellDrawer({ open, toggleDrawer, theme, active, activePrice, eurToUsd, usdToEur, buyOrder, sellOrder, backgroundOrderStyle, handleTabOrderChange, selectedOrderTab, account, setSnackType, setSnackMessage, handleClick }) {
    const [accountType, setAccountType] = useState('Real')
    const { t } = useTranslation()
    const [amount, setAmount] = useState(1)
    const [leverage, setLeverage] = useState(1)
    const [orderType, setOrderType] = useState('')
    const [takeProfit, setTakeProfit] = useState('')
    const [openWhen, setOpenWhen] = useState('')
    const [stopLoss, setStopLoss] = useState('')
    const [expectedProfit, setExpectedProfit] = useState('');

    useEffect(() => {
        if (activePrice > 0 && amount > 0 && leverage > 0 && expectedProfit > 0) {
            const volume = amount * leverage;
            const newTakeProfit = 
                selectedOrderTab === 'buy'
                    ? parseFloat(activePrice) + (expectedProfit / volume)
                    : parseFloat(activePrice) - (expectedProfit / volume);
            setTakeProfit(newTakeProfit.toFixed(2));
        }
    }, [activePrice, amount, leverage, selectedOrderTab, expectedProfit]);
    
    const handleExpectedProfitChange = (e) => {
        const profit = parseFloat(e.target.value);
        setExpectedProfit(profit);
    };
    const handleImgError = (e) => {
        e.target.src = altIcon;
    };

    const OpenPosition = async () => {
        if (!selectedOrderTab) {
            handleClick()
            setSnackMessage(t('OrderTypeError'))
            setSnackType('error')
            return
        }
        if (
            takeProfit
            && selectedOrderTab === 'buy'
            && +takeProfit
            <=
            parseFloat((account?.AccountCurrency == "EUR" ? activePrice * eurToUsd : activePrice))) {
            handleClick()
            setSnackMessage(t('MustBeHigher'))
            setSnackType('error')
            return
        }
        if (
            stopLoss
            && selectedOrderTab === 'buy'
            && +stopLoss
            >=
            parseFloat((account?.AccountCurrency == "EUR" ? activePrice * eurToUsd : activePrice))) {
            handleClick()
            setSnackMessage(t('MustBeLower'))
            setSnackType('error')
            return
        }
        if (
            stopLoss
            && selectedOrderTab === 'sell'
            && +stopLoss
            <=
            parseFloat((account?.AccountCurrency == "EUR" ? activePrice * eurToUsd : activePrice))) {
            console.log(takeProfit)
            handleClick()
            setSnackMessage(t('MustBeHigher'))
            setSnackType('error')
            return
        }
        if (
            takeProfit
            && orderType === 'sell'
            && +takeProfit
            >=
            parseFloat((account?.AccountCurrency == "EUR" ? activePrice * eurToUsd : activePrice))) {
            handleClick()
            setSnackMessage(t('MustBeLower'))
            setSnackType('error')
            return
        }
        if (selectedOrderTab === 'buy') {
            Buy()
        }
        if (selectedOrderTab === 'sell') {
            Sell()
        }
    }
    const Buy = async () => {
        if (!/^(?!0\.?0*$)\d+(\.\d+)?$/.test(amount)) {
            handleClick()
            setSnackMessage(t("BuySellError"))
            setSnackType('error')
            return
        }
        try {
            const { data } = await axios.post(
                process.env.REACT_APP_test_url + `/api/account/assets/${active.Id}/buy/long`,
                {
                    user_id: account?.Id,
                    sum:
                        openWhen
                            ?
                            (parseFloat(((amount * active?.LotSize) * (openWhen)) / leverage)).toFixed(2)
                            :
                            (parseFloat(((amount * active?.LotSize) * (activePrice)) / leverage)).toFixed(2),
                    count: amount,
                    tp: takeProfit ? (account?.AccountCurrency == "EUR" ? takeProfit * usdToEur : takeProfit) : 0,
                    sl: stopLoss ? (account?.AccountCurrency == "EUR" ? stopLoss * usdToEur : stopLoss) : 0,
                    leverage: leverage,
                    openWhen: openWhen ? (account?.AccountCurrency == "EUR" ? openWhen * usdToEur : openWhen) : 0
                },
                {
                    headers: {
                        'Authorization': `Bearer ${secureLocalStorage.getItem('token')}`
                    }
                }
            );
            if (data) {
                handleClick()
                setSnackMessage(t('BuySuccess'))
                setSnackType('success')
                toggleDrawer(false)()
            }
        } catch (e) {
            console.log(e)
            handleClick()
            setSnackMessage(e.response.data.message)
            setSnackType('error')
     
        }
    }
    const Sell = async () => {
        if (!/^(?!0\.?0*$)\d+(\.\d+)?$/.test(amount)) {
            handleClick()
            setSnackMessage(t("BuySellError"))
            setSnackType('error')
            return
        }
        try {
            const { data } = await axios.post(
                process.env.REACT_APP_test_url + `/api/account/assets/${active.Id}/sell/short`,
                {
                    user_id: account?.Id,
                    sum: openWhen
                        ?
                        (parseFloat((amount * active?.LotSize) * openWhen) / leverage).toFixed(2)
                        :
                        (parseFloat((amount * active?.LotSize) * activePrice) / leverage).toFixed(2),
                    count: amount,
                    tp: takeProfit ? (account?.AccountCurrency == "EUR" ? takeProfit * usdToEur : takeProfit) : 0,
                    sl: stopLoss ? (account?.AccountCurrency == "EUR" ? stopLoss * usdToEur : stopLoss) : 0,
                    leverage: leverage,
                    openWhen: openWhen ? (account?.AccountCurrency == "EUR" ? openWhen * usdToEur : openWhen) : 0
                },
                {
                    headers: {
                        'Authorization': `Bearer ${secureLocalStorage.getItem('token')}`
                    }
                }
            );
            if (data) {
                handleClick()
                setSnackMessage(t('SellSuccess'))
                setSnackType('success')
                toggleDrawer(false)()
            }
        } catch (e) {
            console.log(e)
            handleClick()
            setSnackMessage(e.response.data.message)
            setSnackType('error')
        }
    }



    useEffect(() => {
        switch (active?.Type) {
            case 'crypto': setLeverage(10); break;
            case 'shares': setLeverage(100); break;
            case 'share': setLeverage(10); break;
            case 'ruShare': setLeverage(10); break;
            case 'shareIndex': setLeverage(10); break;
            case 'currency': setLeverage(200); break;
            case 'metal': setLeverage(100); break;
            case 'oil_gas': setLeverage(100); break;
            default: setLeverage(1); break;
        }
    }, [active])
    const leverages = [{
        value: 1,
        label: 'x1',
    }, {
        value: 5,
        label: 'x5',
    }, {
        value: 10,
        label: 'x10',
    }, {
        value: 20,
        label: 'x20',
    }, {
        value: 50,
        label: 'x50',
    }, {
        value: 100,
        label: 'x100',
    }, {
        value: 200,
        label: 'x200',
    }, {
        value: 500,
        label: 'x500',
    }]
    const CustomOption = (props) => {
        const { innerProps, innerRef } = props;
        return (
            <div ref={innerRef} {...innerProps} className={props.isSelected ? styles.custom_option_selected : styles.custom_option}>
                <div className={styles.option} style={{ color: props.data.color, backgroundColor: props.data.background, width: 'auto' }}>
                    {props.data.label}
                </div>
            </div>)
    }


    const DropdownIndicator = ({ children, ...props }) => (
        <components.DropdownIndicator {...props}>
            <div className={styles.single_value_container}>
                <div className={styles.custom_icon}>
                    <svg width={"1.7vh"} height={"1.6vh"} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5003 6L8.83366 10L4.16699 6" stroke="#A0A0AB" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                {children}
            </div>
        </components.DropdownIndicator>
    );


    return (
        <Drawer open={open} anchor={window.innerWidth < 600 ? "bottom" : 'right'} onClose={toggleDrawer(false)}
            PaperProps={{
                className: styles.paper,
            }}>
            <div className={styles.account_drawer}>
                <div className={styles.close_icon} onClick={toggleDrawer(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none">
                        <path d="M12 4.00003L4 12M3.99997 4L11.9999 12" stroke="#51525C" strokeLinecap="round" />
                    </svg>
                </div>
                <div className={styles.top_drawer}>
                    <div className={styles.drawer_header} style={{ justifyContent: 'space-between' }}>
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '1.6vh' }}>
                            <img src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${active?.Subtitle.slice(1).slice(0, -1).toLowerCase()}.svg`} alt='icon'
                                onError={handleImgError} />
                            {active?.Subtitle.slice(1).slice(0, -1)}
                        </span>
                        <div className={styles.close_icon_mobile} onClick={toggleDrawer(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none">
                                <path d="M12 4.00003L4 12M3.99997 4L11.9999 12" stroke="#51525C" strokeLinecap="round" />
                            </svg>
                        </div>
                    </div>
                    <div className={styles.drawer_body}>
                        <div className={styles.account_tabs}>
                            <button
                                ref={buyOrder}
                                className={`${styles.tab} ${selectedOrderTab === 'buy' ? styles.active : ''}`}
                                onClick={() => handleTabOrderChange('buy')}
                            >
                                {t("Buy")}
                            </button>
                            <button
                                ref={sellOrder}
                                className={`${styles.tab} ${selectedOrderTab === 'sell' ? styles.active : ''}`}
                                onClick={() => handleTabOrderChange('sell')}
                            >
                                {t("Sell")}
                            </button>
                        </div>
                        <div className={styles.label_input}>
                            <div className={styles.label_balance}>
                                <label htmlFor="name">{t('Volume')}</label>
                                <span className={styles.balance}>{t('Balance')}: <span className={styles.amount}>{account?.AccountCurrency == "EUR" ? "€" : "$"} {parseFloat(account?.AccountBalance * (account?.AccountCurrency == "EUR" ? eurToUsd : 1)).toFixed(1)}</span></span>
                            </div>
                            <div className={styles.volume_input}>
                                <span onClick={() => setAmount(prev => parseFloat(parseFloat(prev) > 0.1?parseFloat(prev) - 0.1:0.1).toFixed(2))}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <circle cx="12" cy="12" r="10" stroke="#1C274C" stroke-width="1.5" />
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 11.5H11.5H14" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
                                        </svg>
                                    </svg>
                                </span>        
                                <input 
                                    type="number" 
                                    value={amount} 
                                    onChange={e => {
                                        const newValue = e.target.value;
                                        if (newValue >= 0) {
                                            setAmount(newValue);
                                        }
                                    }} 
                                    min="0.1" 
                                />
                                <span onClick={() => setAmount(prev => parseFloat(parseFloat(prev) + 0.1).toFixed(2))}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <circle cx="12" cy="12" r="10" stroke="#1C274C" stroke-width="1.5" />
                                        <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                </span>
                            </div>
                        </div>                        
                        <div className={styles.label_input_new}>
                            <label>{t('Margin')}</label>
                            <input placeholder={t('Margin')} readOnly value={`${account?.AccountCurrency == "EUR"
                                    ?
                                    "€"
                                    :
                                    "$"
                                } ${openWhen
                                    ?
                                    (
                                        parseFloat((amount * active?.LotSize)
                                            *
                                            (account?.AccountCurrency == "EUR"
                                                ?
                                                openWhen
                                                :
                                                openWhen)
                                        ) / leverage).toFixed(2)
                                    :
                                    (
                                        parseFloat(
                                            (amount * active?.LotSize)
                                            *
                                            (account?.AccountCurrency == "EUR"
                                                ?
                                                active?.Price * eurToUsd
                                                :
                                                active?.Price)
                                        ) / leverage).toFixed(2)}`} disabled />
                        </div>
                        <div className={styles.label_select}>
                            <label>{t('Leverage')}</label>
                            <div className={styles.select}>
                                <Select
                                    classNamePrefix="custom_select"
                                    className={`${styles.select_select} custom-select-container`}
                                    placeholder={t('Currency')}
                                    isDisabled
                                    menuPlacement='auto'
                                    options={leverages}
                                    styles={colourStylesLeverage(theme)}
                                    value={leverages.find(el => el.value === leverage)}
                                    components={{ DropdownIndicator, IndicatorSeparator: () => null, Option: CustomOption }}
                                />
                            </div>
                        </div>
                        <div className={styles.label_select}>
                            <label>{t('Min step')}</label>
                            <div className={styles.select}>
                                <Select
                                    classNamePrefix="custom_select"
                                    className={`${styles.select_select} custom-select-container`}
                                    placeholder={t('Min step')}
                                    isDisabled
                                    menuPlacement='auto'
                                    options={[]}
                                    styles={colourStylesCurrency(theme)}
                                    value={{ value: 1, label: '0.1' }}
                                    components={{ DropdownIndicator, IndicatorSeparator: () => null, Option: CustomOption }}
                                />
                            </div>
                        </div>
                        <div className={styles.label_input_new}>
                            <label>{t('Stop-Market')}</label>
                            <input type='number' placeholder={t('Stop-Market')} value={openWhen} onChange={e => setOpenWhen(e.target.value)} />
                        </div>
                        <div className={styles.label_input_new}>
                            <label>{t('Take-Profit')}</label>
                            <input type='number' name='take' placeholder={t('Take-Profit')} value={takeProfit} onChange={e => setTakeProfit(e.target.value)} />
                        </div>
                        <div className={styles.label_input_new}>
                            <label>{t('Expected Profit')}</label>
                            <input 
                                type='number' 
                                name='expectedProfit' 
                                placeholder={t('Expected Profit')} 
                                value={expectedProfit} 
                                onChange={handleExpectedProfitChange} 
                            />
                        </div>
                        <div className={styles.label_input_new}>
                            <label>{t('Stop-Loss')}</label>
                            <input type='number' placeholder={t('Stop-Loss')} value={stopLoss} onChange={e => setStopLoss(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className={styles.drawer_footer}>
                    <button style={{ gap: '1.2vh' }} onClick={() => OpenPosition()}>{t('Open Order')}
                        <svg xmlns="http://www.w3.org/2000/svg" width={"1.6vh"} height={"1.6vh"} viewBox="0 0 16 16" fill="none">
                            <g clip-path="url(#clip0_627_6958)">
                                <path d="M1.83301 1.3335C1.83301 1.05735 1.60915 0.833496 1.33301 0.833496C1.05687 0.833496 0.833008 1.05735 0.833008 1.3335V8.03841C0.832999 9.57734 0.832993 10.7834 0.959506 11.7244C1.089 12.6875 1.35923 13.4475 1.95577 14.0441C2.55231 14.6406 3.31232 14.9108 4.27548 15.0403C5.21647 15.1668 6.4225 15.1668 7.96143 15.1668H14.6663C14.9425 15.1668 15.1663 14.943 15.1663 14.6668C15.1663 14.3907 14.9425 14.1668 14.6663 14.1668H7.99967C6.41419 14.1668 5.27538 14.1658 4.40873 14.0492C3.55646 13.9347 3.04264 13.7167 2.66287 13.337C2.2831 12.9572 2.06517 12.4434 1.95059 11.5911C1.83407 10.7245 1.83301 9.58565 1.83301 8.00016V1.3335Z" fill="#FCFCFC" />
                                <path d="M13.058 4.97777C13.2297 4.76152 13.1936 4.447 12.9774 4.27527C12.7611 4.10355 12.4466 4.13964 12.2749 4.35589L10.1962 6.97349C10.0327 7.17938 9.92546 7.31401 9.8371 7.41099C9.75068 7.50586 9.71012 7.53276 9.69166 7.54263C9.55115 7.61775 9.38325 7.6214 9.2396 7.55247C9.22073 7.54341 9.17904 7.5183 9.08857 7.42728C8.99608 7.33423 8.88307 7.20439 8.71077 7.00581L8.70002 6.99341C8.54143 6.81062 8.40619 6.65475 8.28689 6.53473C8.16287 6.40996 8.02726 6.29356 7.85928 6.21295C7.42835 6.00615 6.92466 6.0171 6.50313 6.24244C6.33882 6.33028 6.20839 6.45247 6.08992 6.5825C5.97595 6.7076 5.84762 6.8692 5.69714 7.0587L3.60812 9.68922C3.43639 9.90546 3.47248 10.22 3.68873 10.3917C3.90497 10.5634 4.21949 10.5274 4.39122 10.3111L6.47002 7.69347C6.63352 7.48758 6.74077 7.35296 6.82913 7.25597C6.91556 7.16111 6.95611 7.13421 6.97457 7.12434C7.11508 7.04923 7.28298 7.04557 7.42662 7.11451C7.4455 7.12357 7.48719 7.14868 7.57765 7.2397C7.67015 7.33275 7.78315 7.46258 7.95545 7.66116L7.96622 7.67358C8.12479 7.85634 8.26005 8.01224 8.37934 8.13225C8.50335 8.25702 8.63897 8.37342 8.80695 8.45403C9.23788 8.66083 9.74158 8.64987 10.1631 8.42452C10.3274 8.33668 10.4579 8.21449 10.5763 8.08445C10.6903 7.95937 10.8186 7.79778 10.9691 7.60829L13.058 4.97777Z" fill="#FCFCFC" />
                            </g>
                            <defs>
                                <clipPath id="clip0_627_6958">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </button>
                </div>
            </div>
        </Drawer>
    )
}
